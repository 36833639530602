import React from 'react';
import logo from './logo.png';
import './App.css';

const channelName = "lkngforfootsteps";
const postsCount = 190;
const excludedPosts = [0, 1, 2, 31, 32, 33, 34, 35, 36, 46, 54, 72, 74, 96, 103, 104, 105, 106, 108, 109,
    116, 123, 126, 127, 130, 132, 151, 153, 155, 163, 164, 169, 170, 178, 179, 187, 188, 189];


const queryParams = new URLSearchParams(window.location.search);
let source = queryParams.get('source');

let workAcc = "aleksei_pershinov"
let persAcc = "pershinov"
let tgLink = "https://t.me/"

function getPersLink(source) {
    if (source === "personal") {
        return tgLink + persAcc;
    }
    return tgLink + workAcc;
}

function getChannelLink() {
    return "https://t.me/" + channelName;
}

function buildDataTelegramPostLocation() {
    return channelName + "/" + getRandomPostId();
}

function getRandomPostId() {
    let randId = 0;
    while (true) {
        randId = Math.floor(Math.random() * postsCount);
        if (!excludedPosts.includes(randId)) {
            break;
        }
    }
    return randId;
}

function buildPersonalChannel(source) {
    if (source === "personal") {
        return <div><p className={"subText"}>
            Join my personal <a className="App-link" href={getChannelLink()} target="_blank"
                                rel="noopener noreferrer">Telegram channel 🦶🏿</a>
        </p>
            <div id={"tgScript"}></div></div>;
    }
}

function buildName(source) {
    if (source === "personal") {
        return "Lesha Pershinov";
    }
    return "Aleksei Pershinov";
}

class App extends React.Component {

    componentDidMount() {
        if (source === "personal") {
            const tgPostScript = document.createElement("script");
            tgPostScript.src = "https://telegram.org/js/telegram-widget.js?19";
            tgPostScript.async = true;
            tgPostScript.setAttribute("data-telegram-post", buildDataTelegramPostLocation())
            tgPostScript.setAttribute("data-width", "100%")
            tgPostScript.setAttribute("data-dark", "1")

            document.getElementById("tgScript").appendChild(tgPostScript);
        }
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo"/>
                    {/*<a className={"Jake"} href={"/nft"}><img src={logo} className="App-logo" alt="logo"/>*/}
                    {/*<p className={"subHeaderText"}>*/}
                    {/*    Click the Jake to see some art-works!*/}
                    {/*</p>*/}
                    {/*</a>*/}
                    <p className={"welcome"}>
                        Hi! My name is {buildName(source)}
                    </p>
                    <p className={"whoiam"}>
                        I am a <a
                        href={"https://www.linkedin.com/in/pershinov/"}><code>developer</code> 💻</a>
                    </p>
                    <p className={"contact"}>
                        You can contact me with <a href={getPersLink(source)}><code>Telegram</code> <img
                        className={"iconTelegram"} src="telegram.png"/></a>
                    </p>
                    {/*<a*/}
                    {/*    className="App-link"*/}
                    {/*    href="https://t.me/pershinov"*/}
                    {/*    target="_blank"*/}
                    {/*    rel="noopener noreferrer"*/}
                    {/*>*/}
                    {/*    <img className={"iconTelegram"} src="telegram.png"/> Telegram*/}
                    {/*</a>*/}
                    {buildPersonalChannel(source)}
                </header>
            </div>
        );
    }
}

export default App;
