import React from 'react';
import logo from './logo.png';
import './App.css';

class Nft extends React.Component {

    componentDidMount() {
        const twPostScript = document.createElement("script");
        twPostScript.src = "https://platform.twitter.com/widgets.js";
        twPostScript.async = true;

        document.getElementById("twScript").appendChild(twPostScript);
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <a className={"Jake"} href={"/"}><img src={logo} className="App-logo" alt="logo"/>
                    <p className={"subHeaderText"}>
                        Click the Jake to go home 🏡
                    </p></a>
                    <p className={"welcome"}>
                        Hi! My name is Lesha Pershinov.
                    </p>
                    <p className={"whoiam"}>
                        So, I'm an art-creator and this is NFT created by me
                    </p>
                    <p className={"whoiam-also"}>
                        You can see my works on <a
                        href={"https://beta.disintar.io/profile/EQCn2StJMg3HXJtJCY5qdFIv4WaH5V1RlLuD-85Al-omCykT"}>Disintar 🌅</a> and on <a
                        href={"https://getgems.io/user/EQBJpv-MWoOD1HhqGe91aOki5CsMkOxGZe3l_BhEX8ITBG64"}>GetGems 💎</a>
                    </p>
                    <iframe id={"getGems"} src="https://getgems.io/user/EQBJpv-MWoOD1HhqGe91aOki5CsMkOxGZe3l_BhEX8ITBG64" title="disintar"></iframe>
                    <blockquote className="twitter-tweet"><p lang="en" dir="ltr">The next <a
                        href="https://twitter.com/hashtag/art?src=hash&amp;ref_src=twsrc%5Etfw">#art</a> <a
                        href="https://twitter.com/hashtag/TON?src=hash&amp;ref_src=twsrc%5Etfw">#TON</a> <a
                        href="https://twitter.com/hashtag/nft?src=hash&amp;ref_src=twsrc%5Etfw">#nft</a> is OilEye
                        placed on <a
                            href="https://twitter.com/hashtag/disintar?src=hash&amp;ref_src=twsrc%5Etfw">#disintar</a><a
                            href="https://t.co/giuBoOflOo">https://t.co/giuBoOflOo</a><br></br>You can save it. <a
                            href="https://t.co/ThKOnaVJEK">pic.twitter.com/ThKOnaVJEK</a></p>&mdash; Alexey
                        (@pershinov) <a
                            href="https://twitter.com/pershinov/status/1520770775560900609?ref_src=twsrc%5Etfw">May 1,
                            2022</a></blockquote>
                    {/*<script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>*/}
                    <div id={"twScript"}></div>
                </header>
            </div>
        );
    }
}

export default Nft;
